import styled from 'styled-components';
import MenuImage from '../../assets/img/logo.png';
import SmallImage from '../../assets/img/logo_small.png';

const HeaderWrapper = styled('div')`
  display: grid;
  justify-items: center;
  align-items: center;
  width: 100%;
  position: relative;
`;

const HeaderTextWrapper = styled('div')`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  column-gap: 2rem;
  text-align: center;
`;

const HeaderText = styled('div')`
  text-transform: uppercase;
  font-size: clamp(0.5rem, 1.18vw, 3rem);
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  font-weight: 200;
  @media screen and (max-width: 1440px) {
    font-size: clamp(0.5rem, 1.9vw, 2.5rem);
  }
  @media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 2.4vw, 1.8rem);
  }
`;

const HeaderTextBold = styled('span')`
  text-transform: uppercase;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  font-weight: 900;
`;

const HeaderImg = styled('img')`
  width: 40%;
  @media screen and (max-width: 1440px) {
    width: 75%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const HeaderLogo = styled('img')`
  height: 11rem;
  margin-bottom: 2rem;
`;

const HeaderTitle = styled('div')`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
`;
const HeaderTitleText = styled('h1')`
  color: white;
  font-family: 'DottyFont';
  font-size: 20rem;
  font-weight: 300;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  line-height: 10rem;
`;

const Header = ({ isAdmin }: { isAdmin: boolean }) => {
  return (
    <HeaderWrapper>
      <HeaderTitle>
        <HeaderTitleText>Voyagers</HeaderTitleText>
        <HeaderLogo src={SmallImage} />
      </HeaderTitle>

      {!isAdmin && (
        <HeaderTextWrapper>
          <HeaderText>
            <HeaderTextBold>ID Required</HeaderTextBold>
          </HeaderText>
          <div>
            <HeaderText>
              Min <HeaderTextBold>1 Gram</HeaderTextBold> or{' '}
              <HeaderTextBold>10 euro</HeaderTextBold>
            </HeaderText>
            <HeaderText>
              Max <HeaderTextBold>5 gram</HeaderTextBold> per person
            </HeaderText>
          </div>
        </HeaderTextWrapper>
      )}
      {/* <HeaderImg src={MenuImage} />
      {!isAdmin && (
        <HeaderTextWrapper>
          <HeaderText>
            <HeaderTextBold>ID Required</HeaderTextBold>
          </HeaderText>
          <div>
            <HeaderText>
              Min <HeaderTextBold>1 Gram</HeaderTextBold> or{' '}
              <HeaderTextBold>10 euro</HeaderTextBold>
            </HeaderText>
            <HeaderText>
              Max <HeaderTextBold>5 gram</HeaderTextBold> per person
            </HeaderText>
          </div>
        </HeaderTextWrapper>
      )} */}
    </HeaderWrapper>
  );
};

export default Header;
