import Header from '../../components/header';
import styled from 'styled-components';
import io from 'socket.io-client';
import { useEffect, useRef, useState } from 'react';
import MenuSection from '../../components/menu/section';
import AnimationTv from '../../assets/img/frame.png';
//@ts-ignore
import Video from '../../assets/img/animation.mp4';

const MenuWrapper = styled('div')`
  padding-top: 2rem;
  width: 100%;
  display: grid;
  justify-items: center;
  align-content: start;
  position: relative;
  cursor: none;
  height: 100%;
  overflow: hidden;
`;
const MenuBody = styled('div')`
  width: 100%;
  display: grid;
  column-gap: 2rem;
  grid-template-columns: repeat(4, 1fr);
  font-size: 3.5rem;
  @media screen and (max-width: 1440px) {
    column-gap: 1.5rem;
  }

  @media screen and (max-width: 1024px) {
    column-gap: 1rem;
  }
  @media screen and (max-width: 768px) {
    column-gap: 0.1rem;
  }
`;

const MenuAnimation = styled('div')`
  position: absolute;
  top: 0;
  left: 12.5%;
  transform: translateX(-50%);
  height: 24rem;
`;

const AnimationWrapper = styled('img')`
  left: 0;
  height: 100%;
  z-index: 2;
`;

const Animation = styled('video')`
  z-index: -1;
  position: absolute;
  width: 22.5rem;
  bottom: 50%;
  left: 50%;
  transform: translateX(-46%) translateY(48%);
`;

const sortItems = (category: any, menuItem: any) => {
  if (category.find((category: any) => category.id === menuItem.id)) {
    const filteredItems = category.filter(
      (item: any) => item.id !== menuItem.id
    );
    const sortedList = [...filteredItems, menuItem];

    return sortedList.sort((a: any, b: any) => a.order - b.order);
  } else {
    const sortedList = [...category, menuItem];

    return sortedList.sort((a: any, b: any) => a.order - b.order);
  }
};

const Menu = () => {
  const videoRef: any = useRef();
  const setPlayBack = () => {
    videoRef.current.playbackRate = 0.5;
  };
  const baseUrl = process.env.REACT_APP_SERVER_URL;
  const [section1, setSection1] = useState<any[]>([]);
  const [section2, setSection2] = useState<any[]>([]);
  const [section3, setSection3] = useState<any[]>([]);
  const [section4, setSection4] = useState<any[]>([]);

  useEffect(() => {
    const socket = io(`${baseUrl}/menu?menuId=2435`);
    socket.on('connect', () => {
      console.log('connected to server');
    });

    socket.on('disconnect', () => {
      console.log('disconnected to server');
    });

    socket.on('getMenu', (data: any) => {
      console.log(data);
      setSection1([]);
      setSection2([]);
      setSection3([]);
      setSection4([]);
      data.forEach((menuItem: any) => {
        if (menuItem.section === 1) {
          setSection1((category) => sortItems(category, menuItem));
        } else if (menuItem.section === 2) {
          setSection2((category) => sortItems(category, menuItem));
        } else if (menuItem.section === 3) {
          setSection3((category) => sortItems(category, menuItem));
        } else if (menuItem.section === 4) {
          setSection4((category) => sortItems(category, menuItem));
        }
      });
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <MenuWrapper>
      <Header isAdmin={false} />
      <MenuBody>
        <MenuSection array={section1} />
        <MenuSection array={section2} />
        <MenuSection array={section3} />
        <MenuSection array={section4} />
      </MenuBody>
      <MenuAnimation>
        <AnimationWrapper src={AnimationTv} />
        <Animation
          ref={videoRef}
          onCanPlay={() => setPlayBack()}
          autoPlay
          muted
          loop
          id='animation'
        >
          <source src={Video} type='video/mp4' />
        </Animation>
      </MenuAnimation>
    </MenuWrapper>
  );
};

export default Menu;
